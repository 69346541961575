<template>
      <v-app class>
        <div>
          <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
    </v-alert>
        </div>
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto">
            <v-container>
              <div class="mb-8 mt-8 row">
                <div class="col-md-11">
                  <h1>List of  Contractor Employers</h1>
                </div>
                <div class="col-md-1">
                  <Button :btnType="'Submit'" :label="'+'" @onClick="addNew" class="mr-2"/>
                </div>
              </div>
              <v-text-field v-model="searchPersonnel"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details></v-text-field>
              <div v-if="!contractEmployers.length" class="text-center">
                <v-progress-circular indeterminate
                                     color="primary"></v-progress-circular>
              </div>
              <v-data-table :headers='headers'
                :search="searchPersonnel"
                :items="contractEmployers">
                <template v-slot:item.actions="{ item }">
                  <div class="d-flex">
                    <Button :btnType="'Submit'" :label="'Edit'" @onClick="edit(item)" class="mr-2" />
                    <Button :btnType="'Cancel'" :label="'Delete'" @onClick="openDeleteModal(item)" />
                  </div>
                </template>

              </v-data-table>

              <!-- confirm delete modal  -->
              <Dialog ref="deleteModal" :title="'Are you sure you want to delete'" :width="400">
                <template v-slot:footer>
                  <Button :btnType="'Submit'" :label="'Yes'" @onClick="deleteContractEmployer" class="mr-2" />
                  <Button :btnType="'Cancel'" :label="'No'" @onClick="cancel('deleteModal')" />
                </template>
              </Dialog>
            </v-container>
          </div>
        </div>
      </v-app>
</template>
<script>

import Button from '@/components/ui/form/Button.vue'

import Dialog from '@/components/ui/Dialog.vue'
import { FETCH_CONTRACTEMPLOYER, DELETE_CONTRACTEMPLOYER } from '@/store/action-type'
export default {
  components: {
    Button,
    Dialog

  },
  data () {
    return {
      search: '',
      formData: {
        name: '',
        id: ''
      },
      searchPersonnel: '',
      valid: false,
      isSavingUser: false,
      alertMessage: '',
      alert: false,
      alertType: '',
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'URL',
          value: 'url'
        },
        {
          text: 'Contact Person Email',
          value: 'contactPersonEmail'
        },
        {
          text: 'Contact Person Name',
          value: 'contactPersonName'
        },
        {
          text: 'Contact Person Phone',
          value: 'contactPersonPhone'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  computed: {
    contractEmployers () {
      return this.$store.getters.contractEmployers
    }
  },
  methods: {
    addNew () {
      this.$router.push('/contract-employer')
    },
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => { this.alert = false }, 5000)
    },
    edit (item) {
      this.$router.push(`/contract-employer/${item.id}`)
    },
    openDeleteModal (item) {
      this.$refs.deleteModal.toggleModal()
      this.formData.name = item.name
      this.formData.id = item.id
    },
    deleteContractEmployer () {
      this.cancel('deleteModal')
      this.$store.dispatch(DELETE_CONTRACTEMPLOYER, this.formData.id).then((result) => {
        this.showAlertMessage('Contractor Employer deleted successfully', 'success')
      }).catch(error => {
        console.log(error.message)
        this.showAlertMessage(error.message, 'error')
      })
    },
    // update () {
    //   this.cancel('editModal')
    //   this.$store.dispatch(UPDATE_CONTRACTEMPLOYER, this.formData).then((result) => {
    //     this.showAlertMessage('Work Area Updated successfully await approval', 'success')
    //     this.resetForm()
    //   }).catch(error => {
    //     console.log(error.message)

    //     this.showAlertMessage(error.message, 'error')
    //   })
    // },
    resetForm () {
      this.formData.name = ''
      this.formData.id = ''
    },
    cancel (modal) {
      if (modal === 'editModal') {
        this.$refs.editModal.toggleModal()
      }
      if (modal === 'deleteModal') {
        this.$refs.deleteModal.toggleModal()
      }
    }

  },
  mounted () {
    this.$store
      .dispatch(FETCH_CONTRACTEMPLOYER)
      .then(() => {})
      .catch((error) => {
        if (error) {
          this.showAlertMessage('There are no items to display', 'warning')
        }
      })
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
